import React from "react";
import Container from "../components/container";
import Intro from "../components/intro";
import PageBody from "../components/page-body";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql } from "gatsby";
import Footer from "../components/footer";

export default function Home({ pageContext, data: { site, main, home } }) {

    const pc = pageContext

    return (
        <Container>
            <HelmetDatoCms seo={main.seo} favicon={site.favicon} />
            <Intro pageContext={pc} />
            <PageBody body={home.body} />
            <Footer />
        </Container>
    );
}

export const query = graphql`
  query page($locale: String!){
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    main: datoCmsMain {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    home: datoCmsHome(locale: {eq: $locale}) {
        body
    }
  }
`;
