import React from "react";

export default function Footer() {
  return (
    <footer className="">
        <div className="py-5">
          <img src={'/kast.jpg'} alt="" />
        </div>
    </footer>
  )
}
